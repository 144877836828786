import { Stack, Text } from "@chakra-ui/react";
import { DataHandler } from "@raiden/library-ui/components/DataHandler";
import { useIntl } from "react-intl";
import { CampaignCollapsibleSection } from "../CollapsibleSection";

/**
 * @typedef {object} Props
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Configuration").Configuration>>} configurationFooterResponse
 */

/**
 * @param {Props} props
 */
export function CampaignSeoBlock({ configurationFooterResponse }) {
  const intl = useIntl();

  return (
    <Stack spacing="1.625rem">
      <Text as="h3" variant="h5">
        {intl.formatMessage({
          defaultMessage: "Tous nos hébergements en France",
        })}
      </Text>

      <DataHandler swrResponse={configurationFooterResponse}>
        {({ data: configuration }) => (
          <Stack spacing=".75rem" mx={{ base: 0, md: "1rem" }}>
            {(configuration.campaigns_footer?.guest_house.length ?? 0) > 0 && (
              <CampaignCollapsibleSection
                title={intl.formatMessage({
                  defaultMessage: "Chambres d'hôtes en France",
                })}
                items={configuration.campaigns_footer?.guest_house ?? []}
              />
            )}

            {(configuration.campaigns_footer?.location.length ?? 0) > 0 && (
              <CampaignCollapsibleSection
                title={intl.formatMessage({
                  defaultMessage: "Locations de vacances en France",
                })}
                items={configuration.campaigns_footer?.location ?? []}
              />
            )}

            {(configuration.campaigns_footer?.cottage.length ?? 0) > 0 && (
              <CampaignCollapsibleSection
                title={intl.formatMessage({
                  defaultMessage: "Gîtes en France",
                })}
                items={configuration.campaigns_footer?.cottage ?? []}
              />
            )}
          </Stack>
        )}
      </DataHandler>
    </Stack>
  );
}
