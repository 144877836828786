import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import encodeFormData from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeFormData";
import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useApiError } from "../../../components/ReactHookForm/ApiError";
import { CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_PARTICULAR } from "../../../constants/customers/contacts";
import useRequest from "../../../hooks/useRequest";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";

/**
 * @typedef {object} LeadsFormValues
 * @property {object} data
 * @property {string} data.gender
 * @property {import("../../../types/customers/Contact").CustomerContactCustomerType} data.customer_type
 * @property {string} data.first_name
 * @property {string} data.last_name
 * @property {string} data.company_name
 * @property {string} data.display_phone_country
 * @property {string} data.display_phone
 * @property {string} data.email
 * @property {import("../../../types/Hotel").HotelType | ""} data.hotel_type
 * @property {string} data.department_id
 * @property {string} data.message
 * @property {"label" | "ranking"} data.business_type
 * @property {boolean} data.accept_terms
 * @property {boolean} data.accept_both
 */

export function getLeadsFormDefaultValues({ type }) {
  /** @type {LeadsFormValues} */
  const defaultValues = {
    data: {
      gender: "",
      customer_type: CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_PARTICULAR,
      first_name: "",
      last_name: "",
      company_name: "",
      display_phone_country: "FR",
      display_phone: "",
      email: "",
      hotel_type: "",
      department_id: "",
      message: "",
      business_type: type,
      accept_terms: false,
      accept_both: false,
    },
  };

  return defaultValues;
}

/**
 * @typedef {object} Props
 * @property {"label" | "ranking"} type
 * @property {() => void} [onSuccess]
 * @property {boolean} [withFormTag]
 * @property {(params: {form: import("react-hook-form").UseFormReturn<LeadsFormValues>, submit: () => void, isLoading: boolean}) => import("react").ReactElement} children
 */
/**
 * @param {Props} props
 */
export function LeadsCreateHandler({
  type,
  onSuccess,
  withFormTag = true,
  children,
}) {
  const intl = useIntl();

  /** @type {import("react-hook-form").UseFormReturn<LeadsFormValues>} */
  const form = useForm({
    defaultValues: getLeadsFormDefaultValues({ type }),
    shouldUseNativeValidation: false,
  });
  const { handleSubmit } = form;

  const { request, isLoading, toastError } = useRequest();

  const { resolveApiError, resetApiErrors } = useApiError();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const submit = useCallback(
    (event) => {
      event.preventDefault();
      handleSubmit(async (values) => {
        if (!executeRecaptcha) {
          console.warn("Execute recaptcha not yet available");
          toastError({
            id: "invalid-recaptcha",
            status: "error",
            description: intl.formatMessage({
              defaultMessage: "Impossible de charger le module de sécurité",
            }),
            title: intl.formatMessage({
              defaultMessage: "Erreur",
            }),
            isClosable: true,
          });
          return;
        }

        const token = await executeRecaptcha("submit_form_quotation");

        const payload = {
          data: {
            ...values.data,
            is_pro:
              values.data.customer_type !==
              CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_PARTICULAR,
            company_name:
              values.data.customer_type ===
              CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_PARTICULAR
                ? undefined
                : values.data.company_name,
            business_type: values.data.accept_both === true ? "both" : type,
            customer_type: undefined,
            accept_terms: undefined,
            accept_both: undefined,
          },
          meta: {
            recaptcha_token: token,
          },
        };

        request(
          `${generateApiUrl({
            id: "@leads.create",
          })}/`,
          {
            method: "POST",
            body: encodeFormData(payload),
          },
          {
            inputMode: "formData",
          },
        )
          .then((response) => {
            resetApiErrors();
            onSuccess?.();
          })
          .catch((response) => {
            resetApiErrors();
            resolveApiError({ response, form });
          });
      })();
    },
    [
      executeRecaptcha,
      form,
      handleSubmit,
      intl,
      onSuccess,
      request,
      resetApiErrors,
      resolveApiError,
      toastError,
      type,
    ],
  );

  return (
    <FormProvider {...form}>
      <ConditionalWrapper
        hasWrapper={withFormTag}
        wrapper={(children) => <form onSubmit={submit}>{children}</form>}>
        {children({ form, submit, isLoading })}
      </ConditionalWrapper>
    </FormProvider>
  );
}
